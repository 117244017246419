import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store';

import { OfflineStrategy } from './model';

export const value = (state: RootState) => state.settings.value;
export const request = (state: RootState) => state.settings.request;

export const isLoaded = createSelector(value, (v) => Boolean(v));
export const isLoading = createSelector(request, (r) => r === 'loading');
export const isError = createSelector(request, (r) => r === 'error');
export const neverLoaded = createSelector(
  value,
  request,
  (v, r) => v === null && r === 'idle',
);

export const assistantOnline = createSelector(value, (v) => v?.assistantOnline);

export const widgetSettings = createSelector(value, (v) => v?.widgetSettings);

export const offlineStrategy = createSelector(
  widgetSettings,
  (settings) => settings?.strategy,
);

export const offlineStrategyIsChat = createSelector(
  offlineStrategy,
  (s) => s === OfflineStrategy.CHAT,
);

export const introMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.message,
);

export const legalMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.legalDisclaimer,
);

export const offlineMessageSettings = createSelector(
  value,
  (v) => v?.widgetSettings?.introMessage?.offlineDisclaimer,
);

export const contactFormFields = createSelector(
  value,
  (v) => v?.widgetSettings?.contactForm?.fields,
);
