// All exports here are treated as thunks for communication with worker - use messagesUtility.ts for other exports
import { v4 as uuidv4 } from 'uuid';

import {
  listMessages,
  batchCreateMessage,
} from '@wix/ambassador-innovation-widget-v1-message/http';
import { getConversation } from '@wix/ambassador-innovation-widget-v1-conversation/http';
import { submitContact } from '@wix/ambassador-contacts-v4-submit-contact/http';
import {
  ListMessagesRequest,
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import {
  EmailTag,
  PhoneTag,
  SubmitContactRequest,
} from '@wix/ambassador-contacts-v4-submit-contact/types';

import { senderIsVisitor } from '@/utils/sender';

import { FormData } from 'AiAssistantWidget/Widget/components';
import { FormSubmissionPayload } from 'AiAssistantWidget/Widget/components/ChatMessagePayload/types';

import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { selectLayout } from '../layout';
import { flagsSlice } from '../flags';

import {
  formatMessage,
  formatMessages,
  isAllowedMessage,
  queryBuilder,
  SPECIAL_MESSAGE_TYPES,
} from './utils';
import {
  getDummyAssistantMessages,
  getDummyCardMessages,
  getDummyMessages,
  getDummyVisitorMessages,
} from './dummyMessages';
import { APIMessage, Message, messagesSlice } from './messagesSlice';

import { selectMessages } from '.';

export type DuplexerMessage = Partial<APIMessage> & { expiryMs: number };
export type DuplexerMergeConversatoinsMessage = {
  oldConversationIds: string[];
  targetConversationId: string;
};

export const enum DummyMessageType {
  Visitor,
  Assistant,
  Card,
}

export const getEditorMockMessages = createAppAsyncThunk<
  void,
  DummyMessageType | void
>('messages/getMock', async (dummyMessageType, { dispatch, extra: { t } }) => {
  let messages: APIMessage[];
  switch (dummyMessageType) {
    case DummyMessageType.Assistant:
      messages = getDummyAssistantMessages(t);
      break;
    case DummyMessageType.Visitor:
      messages = getDummyVisitorMessages(t);
      break;
    case DummyMessageType.Card:
      messages = getDummyCardMessages(t);
      break;
    default:
      messages = getDummyMessages(t);
      break;
  }

  dispatch(
    messagesSlice.actions.resetMessages({
      messages: formatMessages(messages),
      pagingMetadata: { tooManyToCount: true, cursors: {} },
    }),
  );
});

export const getMessagesHistory = createAppAsyncThunk(
  'messages/get',
  async (
    query: ListMessagesRequest | undefined = queryBuilder(),
    { dispatch, extra: { httpClient } },
  ) => {
    const resp = await httpClient.request(listMessages(query));
    const { messages, pagingMetadata } = resp.data;

    dispatch(
      messagesSlice.actions.addMessages({
        pagingMetadata,
        messages: formatMessages(messages?.filter(isAllowedMessage)),
      }),
    );
  },
);

export const getConversationId = createAppAsyncThunk(
  'messages/getConversationId',
  async (_, { dispatch, extra: { httpClient } }) => {
    const {
      data: { conversation },
    } = await httpClient.request(getConversation({}));

    if (!conversation?.id) {
      throw new Error('No conversationId in response!');
    }

    dispatch(messagesSlice.actions.setConversationId(conversation!.id!));
    dispatch(flagsSlice.actions.setIsContact(Boolean(conversation!.contact)));

    return conversation!.id;
  },
);

export const subscribeForNewMessages = createAppAsyncThunk(
  'messages/subscribe',
  async (_, { dispatch, getState, extra: { socket, httpClient } }) => {
    if (!socket) {
      // No need to listen in Editor
      return;
    }

    /**
     * TODO: set subscription/connection status in store,
     * handle disconnect/errors with relative status, resubscribe
     */

    const conversation =
      selectMessages.conversationId(getState()) ??
      (await dispatch(getConversationId()).unwrap());

    const channel = socket.connection.subscribe(conversation);
    channel.on('new-message-event', (m: DuplexerMessage) => {
      if (m.id) {
        dispatch(messagesSlice.actions.setIsTyping(false));

        if (!isAllowedMessage(m)) {
          return;
        }

        // It is possible to get event with a message id before we got API response in which case 2 messages are created
        // this mechanic should solve such cases
        const pendingMessages = selectMessages.pendingMessages(getState());
        if (
          pendingMessages &&
          (senderIsVisitor(m.sender) ||
            (m.messageType && SPECIAL_MESSAGE_TYPES.has(m.messageType)))
        ) {
          return;
        }

        const message = formatMessage(m);
        dispatch(messagesSlice.actions.addOrUpdateMessage(message));

        const state = getState();
        if (selectLayout.isMinimized(state)) {
          dispatch(messagesSlice.actions.setUnread(true));
        }
      } else if (m.expiryMs) {
        // Typing notification
        dispatch(messagesSlice.actions.setIsTyping(true));
        // TODO: add timed removal of typing indicator
      }
    });
    channel.on(
      'new-conversation-event',
      async (m: DuplexerMergeConversatoinsMessage) => {
        if (m.targetConversationId) {
          channel.removeAllListeners();
          await dispatch(getConversationId());
          dispatch(
            messagesSlice.actions.resetConversation(m.targetConversationId),
          );
        }
      },
    );
  },
);

export type AddMessagePayload = Partial<Message> & {
  text: string;
};

export const addMessage = createAppAsyncThunk(
  'messages/add',
  async (
    payload: AddMessagePayload | AddMessagePayload[],
    { dispatch, extra: { httpClient }, getState },
  ) => {
    const conversationId = selectMessages.conversationId(getState());

    const msgs = Array.isArray(payload) ? payload : [payload];
    const optimisticUpdateMessages: Message[] = msgs.map((msg) => ({
      id: uuidv4(),
      sender: Sender.UOU,
      messageType: MessageType.QUESTION,
      createdDate: Date.now(),
      conversationId,
      ...msg,
    }));

    optimisticUpdateMessages.forEach((msg) =>
      dispatch(messagesSlice.actions.addOptimisticMessage(msg)),
    );

    const req = batchCreateMessage({
      messages: optimisticUpdateMessages.map(
        ({ text, sender, messageType, answerTo, structPayloads }) => ({
          text,
          sender,
          messageType,
          answerTo,
          structPayloads,
        }),
      ),
    });

    const {
      data: { messages },
      status,
    } = await httpClient.request(req);
    if (status !== 200) {
      // TODO: handle error with UI?
      throw new Error('Failed to add message');
    }

    if (messages?.some((m) => !m.id)) {
      // TODO: should not happen, if yes, fix or make UI
      throw new Error('Some messages were not created');
    }

    messages?.forEach((m, i) => {
      const optimistic = optimisticUpdateMessages[i];
      dispatch(
        messagesSlice.actions.updateMessage({
          id: optimistic.id,
          changes: formatMessage(
            m,
            m.messageType && SPECIAL_MESSAGE_TYPES.has(m.messageType)
              ? optimistic.createdDate
              : undefined,
          ),
        }),
      );
    });
  },
);

export const setRead = createAppAsyncThunk(
  'messages/read',
  async (_, { dispatch }) => {
    dispatch(messagesSlice.actions.setUnread(false));
  },
);

export const submitVisitorAsContact = createAppAsyncThunk(
  'messages/submitContact',
  async (
    { name, email, phone, message, formId }: FormData,
    { dispatch, extra: { httpClient } },
  ) => {
    const structure: SubmitContactRequest = {
      info: {
        emails: { items: [{ email, tag: EmailTag.UNTAGGED }] },
      },
    };

    if (name) {
      structure.info!.name = { first: name };
    }
    if (phone) {
      structure.info!.phones = { items: [{ phone, tag: PhoneTag.UNTAGGED }] };
    }

    await httpClient.request(submitContact(structure));

    dispatch(
      addMessage({
        text: ' ',
        sender: Sender.SYSTEM,
        messageType: MessageType.CONTACT_FORM_SUBMITTED,
        answerTo: formId,
        structPayloads: [
          {
            type: 'form_submitted',
            id: uuidv4(),
            name,
            email,
            phone,
          } as FormSubmissionPayload,
        ],
      }),
    );

    if (message) {
      dispatch(addMessage({ text: message, sender: Sender.UOU }));
    }
  },
);
