import { useEffect, useMemo, useState } from 'react';

import {
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  addMessage,
  AddMessagePayload,
  selectConversationsQuota,
  selectFlags,
  selectIntroMessage,
  selectLegalMessage,
  selectMessages,
  selectOfflineMessage,
  selectOfflineShouldShowContactForm,
  selectSettings,
} from '@/features';
import { useAppDispatch, useAppSelector } from '@/store';

export const useIntroSequence = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isEditor } = useEnvironment();
  const [isSequenceFulfilled, setIsSequenceFulfilled] = useState(true);
  const settingsLoaded = useAppSelector(selectSettings.isLoaded);
  const messagesLoaded = useAppSelector(selectMessages.isLoaded);
  const quotaLoaded = useAppSelector(selectConversationsQuota.isLoaded);

  const introMessage = useAppSelector(selectIntroMessage);
  const legalMessage = useAppSelector(selectLegalMessage);
  const offlineMessage = useAppSelector(selectOfflineMessage);
  const offlineShouldShowContactForm = useAppSelector(
    selectOfflineShouldShowContactForm,
  );
  const offlineStrategyIsChat = useAppSelector(
    selectSettings.offlineStrategyIsChat,
  );
  const maybeSendIntro = useAppSelector(selectMessages.shouldSendIntro);
  const maybeSendLegal = useAppSelector(selectMessages.shouldSendLegal);
  const maybeSendOfflineMessage = useAppSelector(
    selectMessages.shouldSendOfflineMessage,
  );

  const isContact = useAppSelector(selectFlags.isContact);
  const noContactFormOnLastPage = useAppSelector(
    selectMessages.noContactFormOnLastPage,
  );

  const shouldFullfillSequence = useMemo(
    () => settingsLoaded && messagesLoaded && quotaLoaded,
    [settingsLoaded, messagesLoaded, quotaLoaded],
  );

  const shouldSendIntro = useMemo(
    () => Boolean(maybeSendIntro && introMessage),
    [maybeSendIntro, introMessage],
  );

  const shouldSendLegal = useMemo(
    () => Boolean(maybeSendLegal && legalMessage),
    [maybeSendLegal, legalMessage],
  );

  const shouldSendOfflineMessage = useMemo(
    () =>
      Boolean(
        maybeSendOfflineMessage && offlineMessage && offlineStrategyIsChat,
      ),
    [maybeSendOfflineMessage, offlineMessage, offlineStrategyIsChat],
  );

  const shouldSendContact = useMemo(
    () => !isContact && noContactFormOnLastPage && offlineShouldShowContactForm,
    [isContact, noContactFormOnLastPage, offlineShouldShowContactForm],
  );

  const payload = useMemo(() => {
    const result: AddMessagePayload[] = [];
    if (shouldSendIntro) {
      result.push({
        text: introMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.INTRO,
      });
    }
    if (shouldSendLegal) {
      result.push({
        text: legalMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.LEGAL,
      });
    }
    if (shouldSendOfflineMessage) {
      result.push({
        text: offlineMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.OFFLINE,
      });
    }
    if (shouldSendContact) {
      result.push({
        text: t('app.widget.form.offlineBeforeText'),
        sender: Sender.ASSISTANT,
        messageType: MessageType.CONTACT_FORM,
      });
    }
    return result;
  }, [
    shouldSendIntro,
    shouldSendLegal,
    shouldSendContact,
    shouldSendOfflineMessage,
    offlineMessage,
    introMessage,
    legalMessage,
    t,
  ]);

  useEffect(() => {
    if (shouldFullfillSequence && payload.length && !isEditor) {
      setIsSequenceFulfilled(false);
      dispatch(addMessage(payload)).finally(() => {
        setIsSequenceFulfilled(true);
      });
    }
  }, [shouldFullfillSequence, payload, dispatch, isEditor]);

  return {
    isSequenceFulfilled,
  };
};
