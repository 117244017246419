import { useEffect } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { expandWidget } from '@/features/layout/layoutThunks';
import { useAppDispatch, useAppSelector } from '@/store';
import { selectSettings, selectShouldAutoOpenWidget } from '@/features';

export const useForcedExpandInViewer = () => {
  const dispatch = useAppDispatch();
  const { isEditor } = useEnvironment();
  const neverLoadedSettings = useAppSelector(selectSettings.neverLoaded);
  const shouldAutoOpenWidget = useAppSelector(selectShouldAutoOpenWidget);

  useEffect(() => {
    if (isEditor || neverLoadedSettings) {
      return;
    }

    if (
      shouldAutoOpenWidget &&
      !JSON.parse(sessionStorage.getItem('forceExpandedInViewer') ?? 'false')
    ) {
      dispatch(expandWidget());
      sessionStorage.setItem('forceExpandedInViewer', 'true');
    }
  }, [isEditor, neverLoadedSettings, shouldAutoOpenWidget, dispatch]);
};
