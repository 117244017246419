import { createSelector } from '@reduxjs/toolkit';

import { selectConversationsQuota } from '../conversationsQuota';
import { selectSettings, settingsModel } from '../settings';
import { selectFlags } from '../flags';

export const selectIsWidgetOnline = createSelector(
  selectSettings.assistantOnline,
  selectConversationsQuota.isExceeding,
  (assistantOnline, isExceeding) => {
    if (assistantOnline === undefined) {
      return;
    }
    if (isExceeding === undefined) {
      return;
    }

    return assistantOnline && !isExceeding;
  },
);

const { OfflineStrategy } = settingsModel;

export const selectShouldShowWidget = createSelector(
  selectSettings.offlineStrategy,
  selectIsWidgetOnline,
  (strategy, isWidgetOnline) => {
    return (
      isWidgetOnline ||
      (strategy !== undefined && strategy !== OfflineStrategy.HIDDEN)
    );
  },
);

export const selectOfflineDisabledMessaging = createSelector(
  selectIsWidgetOnline,
  selectSettings.offlineStrategy,
  (online, strategy) => !online && strategy === OfflineStrategy.CONTACT,
);

export const selectOfflineShouldShowContactForm = createSelector(
  selectIsWidgetOnline,
  selectSettings.offlineStrategy,
  (online, strategy) =>
    !online &&
    (strategy === OfflineStrategy.CONTACT ||
      strategy === OfflineStrategy.CONTACT_LIGHT),
);

export const selectShouldDisableInput = createSelector(
  selectIsWidgetOnline,
  selectSettings.offlineStrategy,
  selectFlags.isContact,
  (online, strategy, isContact) =>
    !online && strategy === OfflineStrategy.CONTACT && !isContact,
);

export const selectIntroMessage = createSelector(
  selectSettings.introMessageSettings,
  selectIsWidgetOnline,
  (introMessageSettings, online) =>
    online && introMessageSettings?.enabled && introMessageSettings?.text,
);

export const selectLegalMessage = createSelector(
  selectSettings.legalMessageSettings,
  selectIsWidgetOnline,
  (legalMessageSettings, online) =>
    online && legalMessageSettings?.enabled && legalMessageSettings?.text,
);

export const selectOfflineMessage = createSelector(
  selectSettings.offlineMessageSettings,
  selectIsWidgetOnline,
  (offlineMessageSettings, online) =>
    !online && offlineMessageSettings?.enabled && offlineMessageSettings?.text,
);

export const selectShouldAutoOpenWidget = createSelector(
  selectSettings.widgetSettings,
  selectIsWidgetOnline,
  (settings, isOnline) => settings?.enabled && settings?.autoOpen && isOnline,
);
